import React, { Component } from 'react'

export default function() {
    return (
        // <div className="card card-footer text-center p-3 mb-2 bg-dark text-white text-muted">
        //     {/* <p className="card-text"> */}
        //         <a href="">&copy;2022-23 Copyright ICCWC-2023</a>
        //     {/* </p> */}
        //  </div>
        <div className="card card-footer text-center bg-dark text-white">
             &copy;2023-24 Copyright IAICCTSD-2024
        </div>    
    )
}

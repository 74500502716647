import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from './logo.png';
// import org3 from './org3.js'
import $ from 'jquery';

export default function (props) {
    // // const handleClick = () => {
    // //     document.querySelectorAll('.navbar-collapse').hide;
    // // }
    // // let showhide = document.querySelectorAll(".navbar-collapse");
    // // showhide.onClick = () =>{
    // //     document.querySelectorAll(".navbar-collapse .nav-link").hide;
    // // }
    // const showhide = () =>{
    // //     $('.navbar-nav').find('Link').on('click', function(e){
    // //       $('.navbar-toggle').trigger('click');
    // //     });
    
    // //     $(document).on('click','.navbar-collapse.in',function(e) {
    // //       if( $(e.target).is('Link') && ( $(e.target).attr('class') != 'dropdown-toggle' ) ) 
    // //       {
    // //         $(this).collapse('hide');
    // //       }
    // //     });

    // // Navbar show/hide code
    
    //     // var $navbar = $('#main-nav-bar');
    //     // var $navbarCollapse = $('#navcol-2');
    //     // var togglerClicked = false;
    //     // $('.navbar-toggler').on('click', function() {
    //     //     togglerClicked = true;
    //     //     Logger.debug('Navbar toggled')
    //     // });
    //     // $navbar.on('mouseleave', function() {
    //     //     collapseNavbarIfExpanded();
    //     // });
    //     // // Collapse navbar when a link or button inside it is clicked
    //     // $navbarCollapse.on('click', 'a, button', function() {
    //     //     collapseNavbarIfExpanded();
    //     // });
    //     // function collapseNavbarIfExpanded() {
    //     //     Logger.debug('Navbar collapse if expanded')
    //     //     if (togglerClicked && $navbarCollapse.hasClass('show')) {
    //     //         var navbarCollapseInstance = new bootstrap.Collapse($navbarCollapse[0]);
    //     //         navbarCollapseInstance.hide();
    //     //         togglerClicked = false; // reset the flag
    //     //         Logger.debug('Navbar was collapsed')
    //     //     }   
    //     // }

    //     // Wait for the DOM to load
    //     document.addEventListener("DOMContentLoaded", function () {
    //         // Get all navigation links
    //         const navLinks = document.querySelectorAll(".nav-link");
    //         const navbarToggler = document.querySelector(".navbar-toggle");
    //         const navbarCollapse = document.querySelector(".navbar-collapse");

    //         // Add click event listener to each navigation link
    //         navLinks.forEach(function (link) {
    //         link.addEventListener("click", function () {
    //             if (navbarCollapse.classList.contains("show")) {
    //             navbarToggler.click(); // Simulate a click on the toggler to close the menu
    //             }
    //         });
    //         });

    //         // Add click event listener to the document
    //         document.addEventListener("click", function (event) {
    //         // Check if the clicked element is not part of the menu or the toggler
    //         if (!navbarCollapse.contains(event.target) && !navbarToggler.contains(event.target)) {
    //             navbarCollapse.classList.remove("show"); // Hide the menu
    //         }
    //         });

    //         // Add mouseleave event listener to the menu
    //         navbarCollapse.addEventListener("mouseleave", function () {
    //         navbarCollapse.classList.remove("show"); // Hide the menu when the mouse leaves
    //         });
    //     });
    // }
    return (
        <nav className="navbar sticky-top navbar-expand-lg" style={{backgroundColor: "#209fae"}}>
            <div className="container-fluid">
                {/* <img src={logo} alt=""/> */}
                <Link className="navbar-brand" to="/">
                    <img src={logo} alt=""/>
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link className="nav-link active navbar-toggle" aria-current="page" to="/">
                                HOME
                            </Link>
                        </li>
                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle text-dark" to="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            COMMITTEE
                            </Link>
                            <ul className="dropdown-menu" style={{backgroundColor: "#209fae"}}>
                            <li><Link className="dropdown-item" to="/advComm">ADVISORY COMMITTEE</Link></li>
                            <li><Link className="dropdown-item" to="/techComm">TECHNICAL PROGRAM COMMITTEE</Link></li>
                            <li><Link className="dropdown-item" to="/orgComm">ORGANIZING COMMITTEE</Link></li>
                            <li><Link className="dropdown-item" to="/stuComm">STUDENT COMMITTEE</Link></li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link active" aria-current="page" to="/keynote">KEYNOTE SPEAKERS</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle text-dark" to="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            FOR AUTHORS
                            </Link>
                            <ul className="dropdown-menu" style={{backgroundColor: "#209fae"}}>
                            <li><Link className="dropdown-item" to="/tracks">TRACKS</Link></li>
                            <li><Link className="dropdown-item" to="/registration">REGISTRATION</Link></li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle text-dark" to="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            GENERAL INFO
                            </Link>
                            <ul className="dropdown-menu" style={{backgroundColor: "#209fae"}}>
                            <li><Link className="dropdown-item" to="/how">HOW TO REACH</Link></li>
                            <li><Link className="dropdown-item" to="/abouttiet">ABOUT TIET</Link></li>
                            <li><Link className="dropdown-item" to="/gallery">GALLERY</Link></li>
                            <li><Link className="dropdown-item" to="/near">NEAR BY PLACES</Link></li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link active" aria-current="page" to="/publications">PUBLICATIONS</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link active" aria-current="page" to="/impDates">IMPORTANT DATES</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link active" aria-current="page" to="/program">PROGRAM SCHEDULE</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link active" aria-current="page" to="/contactUs">CONTACT US</Link>
                        </li>
                    </ul>
                 </div>
            </div>
        </nav>
    )
}
import React from 'react'
import backgrd from './background.jpg'

export default function() {
    return (
        <>
            {/* <div style={{backgroundImage: 'URL("")'}}>
                <h1 className="text-center"><strong>TECHNICAL PROGRAM COMMITTEE</strong></h1>
            </div> */}
            <div className='d-flex bd-highlight text-center'>
                <h1 className='img-fluid' style={{backgroundImage: `URL(${backgrd})`, backgroundSize: 'cover' ,height: '300px', width: '100%', justifyContent: 'center', textSizeAdjust: '50px', color: 'white', display: 'flex', alignItems: 'center', fontSize: '70px'}}><strong>TECHNICAL PROGRAM COMMITTEE</strong></h1>
            </div>
            <div className='container my-5'>
                <h3><strong>TECHNICAL PROGRAM COMMITTEE</strong></h3>
                <ul>
                    {/* <li>Achyut Sharma, G.D. Goenka University Gurgram</li>
                    <li>Sumit Singh Dhanda, Galgotias University, Greater Noida</li>
                    <li>Dr ARCHEK PRAVEEN KUMAR, JNTU</li>
                    <li>Prof.(Dr.)Arvind Kumar Upadhyay, Amity University Madhya Pradesh Gwalior</li>
                    <li>VINOD KUMAR SHARMA, Amity University Rajasthan</li>
                    <li>Dr.Shilpam Saxena, Oriental Group of Institutions</li>
                    <li>Dr. Shyam Lal, NITK Surathkal Karnataka </li>
                    <li>Dr. Mandeep Singh, NITK Surathkal</li>
                    <li>Dr. Sangeeta Kumari, Bennett University (Times of India Group), Greater Noida, U.P., India</li>
                    <li>Ashish Singh, NMAMIT</li>
                    <li>Dr. Sita Rani, Guru Nanak Dev Engineering College, Ludhiana</li>
                    <li>Mukta Nivelkar, Fr. Conceicao Rodrigues Institute of Technology, Vashi, Navi Mumbai</li>
                    <li>Arun Kumar Singh, Sikkim Manipal Institute of Technology, Sikkim Manipal University</li>
                    <li>Dr. Atul Makrariya, IIMT UNIVERSITY MEERUT</li>
                    <li>Dr. Smitha G Prabhu, NMIT</li>
                    <li>Dr Ravishankar Dudhe, Manipal Academy of Higher education Dubai</li>
                    <li>Dr. Lokesh Sharma, Manipal University Jaipur</li>
                    <li>Dr. Shalini Puri, Manipal University Jaipur</li>
                    <li>Dr. Shivani Gupta, Vellore institute of technology Chennai</li>
                    <li>Dr. Satyajit Mahapatra , Department of Information Communication Technology, MIT Manipal</li>
                    <li>Jagadish Nayak, BITS Pilani Dubai Campus</li> */}






                    {/* <li>Prof. Arvind Kumar Upadhyay, Professor (CSE),Amity University Madhya Pradesh Gwalior</li>
                    <li>Dr. Lokesh Sharma, Associate Professor, Manipal University Jaipur</li>
                    <li>Dr. Shyam Lal, Associate Professor, NITK Surathkal Karnataka</li>
                    <li>Dr. Ashish Singh, Associate Professor, NMAMIT</li>
                    <li>Dr. Smitha G Prabhu, Associate Professor, NMIT</li>
                    <li>Dr. Shilpam Saxena, Associate Professor	Oriental Group of Institutions</li>
                    <li>Dr. Vinod Kumar Sharma, Assistant Professor, Amity University Rajasthan</li>
                    <li>Dr. Mandeep Singh,Assistant Professor, NITK Surathkal</li>
                    <li>Dr. Mandeep Singh,Assistant Professor, NITK Surathkal</li>
                    <li>Dr. Mukta Nivelkar, Assistant professor, Fr. Conceicao Rodrigues Institute of Technology, Vashi, Navi Mumbai.</li>
                    <li>Dr. Shalini Puri, Assistant Professor,Manipal University Jaipur</li>
                    <li>Dr. Shivani Gupta,Assistant Professor Senior, Vellore institute of technology Chennai</li> */}


                    <li>Dr. Álvaro Rocha, University of Lisbon, Portugal</li>
                    <li>Dr. Anand Nayyar, Duy Tan University, Vietnam</li>
                    <li>Dr. Dr Sameerchand Pudaruth, University of Mauritius, Mauritius</li>
                    <li>Dr. Anil Suroliya, Amity University, Tashkent, Uzbekistan</li>
                    <li>Dr. Aruna Kumar S V, University of Beira Interior Covilha, Portugal</li>
                    <li>Dr. Brij B. Gupta, Asia University, Taiwan</li>
                    <li>Dr. Dr. Sheeba Armoogum, University of Mauritius, Mauritius</li>
                    <li>Dr. Dilbag Singh, Gwangju Institute of Science and Technology,, South Korea.</li>
                    <li>Dr. Ganesh Nayak, University of Sydney, Australia</li>
                    <li>Dr. Gaurav Aggarwal, Amity University, Tashkent, Uzbekistan</li>
                    <li>Dr. Jagadeesh Nayak, BITS, Dubai</li>
                    <li>Dr. Jaume Anguera, Universiatat Ramon Llull, Barcelona</li>
                    <li>Dr. Mohammad Abu Kausar, University of Nizwa, Sultanate of Oman</li>
                    <li>Dr. Pinnamaneni Bhanu Prasad, Kelenn Technology, France</li>
                    <li>Dr. Rajiv Selvam, MAHE, Dubai</li>
                    <li>Dr. Ramaprasad Poojary, MAHE, Dubai</li>
                    <li>Dr. Ravishankar Dudhe,MAHE ,  Dubai</li>
                    <li>Dr. Samrat Kumar Dey, Bangladesh Open University, Bangladesh</li>
                    <li>Dr. Siddhaling U, BITS Dubai, BITS, Dubai</li>
                    <li>Dr. Sudhanshu Tripathi, Amity University, Uzbekistan</li>
                    <li>Dr. Thanh-Phong Dao, Ton Duc Thang University, Vietnam</li>
                    <li>Dr. Vishal Mathur, American International University, Kuwait</li>
                    <li>Dr. Wanyang Dai, Nanjing University, China</li>
                    <li>Dr. Zayar Aung, Applies Mathematics and Informatics National Research University, Russian</li>
                    <li>Dr. Ali Kadhum Idrees, University of Babylon, Iraq</li>
                    <li>Dr. Popentiu Vladicescu Florin, University POLITEHNICA of Bucharest, Romania</li>
                    <li>Dr. Selwyn Piramuthu, University of Florida, Florida</li>


                    <li>Dr. Arvind Kumar Upadhyay, Amity University Madhya Pradesh, Gwalior</li>
                    <li>Dr. Lokesh Sharma, Manipal University, Jaipur</li>
                    <li>Dr. Shyam Lal, National Institute of Technology Karnataka, Surathkal</li>
                    <li>Dr. Ashish Singh, Nitte Mahalinga Adyantaya Memorial Institute of Technology (NMAMIT),Nitte</li>
                    <li>Dr. Smitha G Prabhu, Nitte Meenakshi Institute of Technology, Bangalore</li>
                    <li>Dr. Shilpam Saxena, Oriental Group of Institutions</li>
                    <li>Dr. Vinod Kumar Sharma, Amity University, Rajasthan</li>
                    <li>Dr. Mandeep Singh, National Institute of Technology Karnataka, Surathkal</li>
                    <li>Dr. Mukta Nivelkar, Fr. Conceicao Rodrigues Institute of Technology, Vashi, Navi Mumbai.</li>
                    <li>Dr. Shalini Puri, Manipal University, Jaipur</li>
                    <li>Dr. Shivani Gupta, Vellore Institute of Technology, Chennai</li>
                    <li>Dr. Subiman Chatterjee, Indian Institute of Engineering Science and Technology, Shibpur</li>
                    <li>Dr. Radhika Gour, Indian Institute of Information Technology, Allahabad</li>
                    <li>Dr. Himanshu Maurya, Indian Institute of Information Technology, Allahabad</li>
                    <li>Dr. Nikhil Sharma, The LNM Institute of Information Technology, Jaipur</li>
                    <li>Dr. Geetanjali, Chitkara institute of engineering and technology.</li>
                    <li>Dr. Aarti Bansal, Chitkara institute of engineering and technology.</li>
                    <li>Dr. Paras Chawla, IT Academics West, iNuture Education solutions (p) Ltd</li>
                    <li>Dr. Sukhdeep Kaur, Chandigarh College of Engineering, Mohali</li>
                    <li>Dr. Pankaj Mohindru, ECE, UIET, Punjabi University, Patiala</li>
                    <li>Dr. Sonia, BBSBEC, Fatehgarh Sahib</li>
                    <li>Dr. Ajeet Singh, KIET Group of Institutions, Ghaziabad</li>
                    <li>Dr. Poonam Jindal, NIT Kurukshetra</li>
                    <li>Dr. Chirag Arora, KIET Group of Institutions, Ghaziabad</li>
                    <li>Dr. Mandar Karyakarte, Vishwakarma Institute of Information Technology, Pune </li>
                    <li>Dr. Kiran Ahuja, D A V Engineering College, Jallandhar</li>
                    <li>Dr. Sachin Yada, GLA University, Mathura</li>
                    <li>Dr. Naveen Kumar, Christ University , Bangalore</li>
                    <li>Dr. Pradeep Kumar, University of KwaZulu-Natal, South Africa</li>
                    <li>Dr. Pawan Kumar Singh, SRM University Delhi-NCR, Sonepat (Haryana)</li>
                    <li>Dr. Rajesh Malik, Principal-Indus Degree College, KINANA , Jind Haryana</li>
                    <li>Dr. Sandeep Kumar, Jawaharlal Nehru University, New Delhi</li>
                </ul>
            </div>
        </>
    )
}
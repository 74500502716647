import React from 'react'
import Box from '@mui/material/Box';
import backgrd from './background.jpg'

export default function() {
    return (
      <>
       <div className='d-flex bd-highlight text-center'>
            <h1 className='img-fluid' style={{backgroundImage: `URL(${backgrd})`, backgroundSize: 'cover' ,height: '300px', width: '100%', justifyContent: 'center', textSizeAdjust: '50px', color: 'white', display: 'flex', alignItems: 'center', fontSize: '70px'}}><strong>PROGRAM SCHEDULE</strong></h1>
        </div>
        <p className='container' style={{animation: 'glowing 1300ms infinite', height: '150px', fontSize: '100px', textAlign: 'center'}}>COMING SOON!</p>
      </>
    )
}
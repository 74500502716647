import React from 'react'
import backgrd from './background.jpg';
import cct from './cct.jpeg';
import mvk from './mvk.png';
import cm from './madhu-chetty.png';
import ja from './ja.jpg';

export default function() {
    return (
      <>
       <div className='d-flex bd-highlight text-center'>
            <h1 className='img-fluid' style={{backgroundImage: `URL(${backgrd})`, backgroundSize: 'cover' ,height: '300px', width: '100%', justifyContent: 'center', textSizeAdjust: '50px', color: 'white', display: 'flex', alignItems: 'center', fontSize: '70px'}}><strong>KEYNOTE SPEAKERS</strong></h1>
        </div>
        {/* <p className='container' style={{animation: 'glowing 1300ms infinite', height: '150px', fontSize: '100px', textAlign: 'center'}}>TBD</p> */}

        <div className="container my-5" style={{width: "65%"}}>
                <div className="row row-cols-1 row-cols-md-2 g-4">
                    <div className="col">
                        <div className="card h-100 text-center">
                        <img src={mvk} className="card-img-top" alt="..." style={{height: "100%"}}/>
                        <div className="card-body">
                            <p className="card-title"><strong>Prof. MV Kartikeyan</strong></p>
                            <p className="card-text">Director, IIITDM-Kancheepuram</p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center">
                        <img src={cct} className="card-img-top" alt="..." style={{height: "100%"}}/>
                        <div className="card-body">
                            <p className="card-title"><strong>Prof. CC Tripthati</strong></p>
                            <p className="card-text">Director & Member-Secretary, NITTTR, Bhopal</p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center">
                        <img src={cm} className="card-img-top" alt="..." style={{height: "90%"}}/>
                        <div className="card-body">
                            <p className="card-title"><strong>Prof. Chetty, Madhu</strong></p>
                            <p className="card-text">Federation University, Australia</p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center">
                        <img src={ja} className="card-img-top" alt="..." style={{height: "90%"}}/>
                        <div className="card-body">
                            <p className="card-title"><strong>Dr. Jaume Anguera</strong></p>
                            <p className="card-text">Universitat Ramon LLull</p>
                        </div>
                        </div>
                    </div>
                  </div>
            </div>
      </>
    )
}


// Prof. Praveen Agarwal, Vice Principal- 
// Anand International College of Engineering, India
//https://www.springer.com/series/17374
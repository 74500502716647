import React from 'react'
import Box from '@mui/material/Box';
import backgrd from './background.jpg'

export default function() {
    return (
      <>
       <div className='d-flex bd-highlight text-center'>
            <h1 className='img-fluid' style={{backgroundImage: `URL(${backgrd})`, backgroundSize: 'cover' ,height: '300px', width: '100%', justifyContent: 'center', textSizeAdjust: '50px', color: 'white', display: 'flex', alignItems: 'center', fontSize: '100px'}}><strong>REGISTRATION</strong></h1>
        </div>
        {/* <p className='container' style={{animation: 'glowing 1300ms infinite', height: '150px', fontSize: '100px', textAlign: 'center'}}>TBD</p> */}
        <div className="container container-1 my-5" style={{padding: '3vh', transition: '3s', boxShadow: '0 4px 8px 0 rgba(0,0,0,.2)'}}>
                <table className="table table-striped text-center hoverable">
                    <thead>
                        <tr>
                        <th scope="col" style={{backgroundColor: 'rgb(73 179 6)'}}>DELEGATES</th>
                        <th scope="col" style={{backgroundColor: 'rgb(179 163 6)'}}>INDIAN(INR)</th>
                        <th scope="col" style={{backgroundColor: 'rgb(179 163 6)'}}>INTERNATIONAL(USD)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td><strong>Student/research scholar:</strong></td>
                        <td>5000</td>
                        <td>100</td>
                        </tr>
                        <tr>
                        <td><strong>Faculty from Academics:</strong></td>
                        <td>6000</td>
                        <td>150</td>
                        </tr>
                        <tr>
                        <td><strong>Delegates from industry:</strong></td>
                        <td>7000</td>
                        <td>200</td>
                        </tr>
                        <tr>
                        <td><strong>Attendee:</strong></td>
                        <td>3000</td>
                        <td>50</td>
                        </tr>
                    </tbody>
                </table>
            </div>
      </>
    )
}
import React from 'react'
import backgrd from './background.jpg'

export default function() {
    return (
        <>
            {/* <div className='img-fluid my-5'>
                <h1 className="text-center"><strong>STUDENT COMMITTEE</strong></h1>
            </div> */}
            <div className='d-flex bd-highlight text-center'>
                <h1 className='img-fluid' style={{backgroundImage: `URL(${backgrd})`, backgroundSize: 'cover' ,height: '300px', width: '100%', justifyContent: 'center', textSizeAdjust: '50px', color: 'white', display: 'flex', alignItems: 'center', fontSize: '60px'}}><strong>STUDENT COMMITTEE</strong></h1>
            </div>
            <div className="container my-5" style={{width: "75%"}}>
                <div class="row row-cols-1 row-cols-md-3 g-4">
                    <div class="col">
                        <div class="card h-100 text-center">
                            {/* <img src="..." class="card-img-top" alt="..."/> */}
                            <div class="card-body">
                                <p class="card-title"><strong>AKARSH MADAN</strong></p>
                                <p class="card-text">Student, ECE</p>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card h-100 text-center">
                            {/* <img src="..." class="card-img-top" alt="..."/> */}
                            <div class="card-body">
                                <p class="card-title"><strong>KAVISH ARORA</strong></p>
                                <p class="card-text">Student, ECE</p>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card h-100 text-center">
                            {/* <img src="..." class="card-img-top" alt="..."/> */}
                            <div class="card-body">
                                <p class="card-title"><strong>ISHAN GROTRA</strong></p>
                                <p class="card-text">Student, ECE</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
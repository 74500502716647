import React from 'react'
import Box from '@mui/material/Box';
import backgrd from './background.jpg'
import thaparabove from './thaparabove.jpg'
import library from './library.jpg'

export default function() {
    return (
      <>
       <div className='d-flex bd-highlight text-center'>
            <h1 className='img-fluid' style={{backgroundImage: `URL(${backgrd})`, backgroundSize: 'cover' ,height: '300px', width: '100%', justifyContent: 'center', textSizeAdjust: '50px', color: 'white', display: 'flex', alignItems: 'center', fontSize: '100px'}}><strong>ABOUT TIET</strong></h1>
        </div>
        <div className="container">
          <img src={thaparabove} alt="" className="img-fluid my-3 rounded mx-auto d-block" />
          <p className='my-2' style={{textAlign: 'justify'}}>Thapar Institute of Engineering and Technology (TIET), formerly Thapar University, is a deemed-to-be-  university, in Patiala, INDIA. It was founded in 1956 by Mr. Karam Chand Thapar. Thapar Institute of  Engineering and Technology (TIET) is one of India’s oldest and finest educational institutions, which  provides a steady source of highly skilled talent to the nation and overseas. Founded in 1956 with a  campus spread across sprawling 250 acres, the institute in Patiala has been a pioneer in engineering  education, research, and innovation. Our community involves ingenious minds solvers eager to make  the world a better place to live in with their innovative techniques and discoveries. Rated amongst  the country's top-ranked innovation-driven private universities and technical institutes, TIET has  been accredited with an A+ grade by the National Assessment and Accreditation Council (NAAC).  Currently, the institute is ranked 20th in the country by NIRF. Our alumni have stood out in various  fields, such as business and industry, administrative and regulatory services, research and education,  and social and human rights organizations. The institute is awarded a prestigious PURSE Grant of  2023.</p>
          <img src={library} alt="" className="img-fluid my-5 rounded mx-auto d-block" />
          <p className='my-2' style={{textAlign: 'justify'}}>The Department of Electronics and Communication Engineering was established in 1975. It aims to produce quality professionals in this field to compete globally and excel by carrying out basic and applied research in emerging areas by forging strong industry-institute interaction. All programs of the department of ECE are AICTE approved. The B.E. Electronics and Communication Engineering Program is accredited by the NBA and EAC Commission of ABET</p>
        </div>
      </>
    )
}
import React from 'react'
import backgrd from './background.jpg'

export default function() {
    return (
        <>
            {/* <div style={{backgroundImage: 'URL("")'}}>
                <h1 className="text-center"><strong>TRACKS</strong></h1>
            </div> */}
            <div className='d-flex bd-highlight text-center'>
                <h1 className='img-fluid' style={{backgroundImage: `URL(${backgrd})`, backgroundSize: 'cover' ,height: '300px', width: '100%', justifyContent: 'center', textSizeAdjust: '50px', color: 'white', display: 'flex', alignItems: 'center', fontSize: '100px'}}><strong>TRACKS</strong></h1>
            </div>
            <div className='container my-5'>
                <h3><strong>IAICCTSD is focused on UN approved the 2030 Agenda, indicating 17 Sustainable Development Goals (SDG).</strong></h3>
                <h3><strong>Authors are invited to submit their latest original and unpublished research work/results in the following areas, but not limited to:</strong></h3>
                <div class="row row-cols-1 row-cols-md-2 g-4 my-3">
                    <div class="col">
                        <div class="card h-100 bg-light text-dark">
                            <div class="card-body" style={{backgroundColor: "#d3d3d3"}}>
                            <h2 className='text-center'><strong>TRACK: Signal and Image Processing</strong></h2>
                            <ul className='my-3'>
                                <li>Big Data</li>
                                <li>3D Imaging</li>
                                <li>Biomedical applications</li>
                                <li>Deep Learning/Machine Learning for sustainable development</li>
                                <li>Audio & Acoustic Signal Processing</li>
                                <li>Cyber Security and Information Forensics</li>
                                <li>Multidimensional Signal Processing</li>
                                <li>Noise Reduction Algorithms</li>
                                <li>Quantum Communication for sustainable development</li>
                                <li>Emerging Communication Technologies</li>
                                <li>Image Generation, Acquisition, and Processing</li>
                                <li>Motion and Tracking Algorithms and Applications</li>
                                <li>Image Compression, Coding and Encryption</li>
                                <li>Multi-resolution Imaging Techniques</li>
                                <li>Video Signal Processing</li>
                            </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card h-100 bg-light text-dark">
                            <div class="card-body" style={{backgroundColor: "#d3d3d3"}}>
                                <h2 className='text-center'><strong>TRACK: Subtracks For Networking</strong></h2>
                                <ul className='my-3'>
                                    <li>5G and beyond</li>
                                    <li>Mobile edge computing</li>
                                    <li>Software-Defined Networking (SDN)</li>
                                    <li>Network Function Virtualization (NFV)</li>
                                    <li>Cloud-native networking</li>
                                    <li>Data center design and optimization</li>
                                    <li>Cybersecurity and threat detection</li>
                                    <li>Network access control</li>
                                    <li>Intrusion detection and prevention</li>
                                    <li>IoT communication protocols</li>
                                    <li>Edge computing for IoT</li>
                                    <li>IoT network management</li>
                                    <li>Security in IoT networks</li>
                                    <li>Quantum networking</li>
                                    <li>Blockchain in networking</li>
                                    <li>Edge computing and fog networking</li>
                                    <li>6G and sustainable development-oriented future network technologies</li>
                                    <li>Network monitoring and troubleshooting</li>
                                    <li>Quality of Service (QoS)</li>
                                    <li>Network optimization</li>
                                    <li>Capacity planning and traffic engineering</li>
                                    <li>Integration of IoT with Sustainable Development Goals</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div className="card mb-3">
                            <div class="card h-100 bg-light text-dark">
                                <div class="card-body" style={{backgroundColor: "#d3d3d3"}}>
                                    <h2 className='text-center'><strong>TRACK: Communication & Networking</strong></h2>
                                    <ul className='my-3'>
                                        <li>Green Communication Networks for Sustainable Growth</li>
                                        <li>Wireless Communication & Networks</li>
                                        <li>Optical Communications & Networks</li>
                                        <li>Modulation and Coding Techniques</li>
                                        <li>Ad-hoc, Mesh and Sensor Networks</li>
                                        <li>Vehicular Networks</li>
                                        <li>Cellular Networks</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="card mb-3">
                            <div class="card h-100 bg-light text-dark">
                                <div class="card-body" style={{backgroundColor: "#d3d3d3"}}>
                                    <h2 className='text-center'><strong>TRACK: Computation and Artificial intelligence</strong></h2>
                                    <ul className='my-3'>
                                        <li>Machine and Deep Learning for Remote Sensing Applications</li>
                                        <li>Machine and Deep Learning for Healthcare Applications</li>
                                        <li>Deep Learning Architecture and Applications</li>
                                        <li>AI/ML for Cyber-Physical Systems</li>
                                        <li>Testing, Debugging, and Monitoring of ML Applications</li>
                                        <li>Cloud Computing for Machine and Deep Learning</li>
                                        <li>AI/ML for VLSI and Architecture Design</li>
                                        <li>AI/ML for Cloud Computing</li>
                                        <li>AI/ML for IoT Networks</li>
                                        <li>AI/ML for Application Case Studies in sustainable development</li>
                                        <li>AI/ML Based Computational Optimization</li>
                                        <li>Engineering Optimization and Design</li>
                                        <li>Mathematical Modeling and Applications</li>
                                        <li>Artificial Intelligence and Machine Learning via Fractional Order Systems</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card h-100 bg-light text-dark">
                            <div class="card-body" style={{backgroundColor: "#d3d3d3"}}>
                                <h2 className='text-center'><strong>TRACK: RF and Microwave</strong></h2>
                                <ul className='my-3'>
                                    <li> Smart antennas, MIMO, Massive MIMO, and beam-forming</li>
                                    <li>Millimeter-wave/THz and optical antennas</li>
                                    <li>Small antennas, broadband, and multi-band antennas</li>
                                    <li>Antenna measurements, reconfigurable antennas</li>
                                    <li>Planar and conformal antennas</li>
                                    <li>Wearable, implantable, and textile antennas</li>
                                    <li>UWB antennas and time domain techniques</li>
                                    <li>Array antennas, phased arrays, reflect arrays</li>
                                    <li>Machine learning for antennas, 5G antennas</li>
                                    <li>Antennas for biomedical and health</li>
                                    <li>Automotive antennas, antennas for SDR/cognitive radio</li>
                                    <li>3D printed antennas, RFID, and IoT antennas</li>
                                    <li>Satellite antennas and other topics of antenna and applications</li>
                                    <li>RF and Microwave Sustainability oriented technologies</li>
                                    <p className="text-center my-3"><strong>Microwave Components:</strong></p>
                                    <li>High-power devices and circuits, wide band-gap devices</li>
                                    <li>Microwave tubes, control circuits (mixer, oscillator, switch, etc.)</li>
                                    <li>MMICs, RFICs, millimeter and THz wave devices and circuits</li>
                                    <li>Graphene devices, rectennas, reconfigurable filters, resonators</li>
                                    <li>Directional couplers and hybrids, waveguides and transmission lines</li>
                                    <li>Isolators, power dividers, and other topics</li>
                                    <p className="text-center my-3"><strong>Metasurfaces and Metamaterials:</strong></p>
                                    <li>EM theory, left-handed material (LHM), ENG, DNG, MNG, ZIM, HIS</li>
                                    <li>EBG structures, absorbers, polarizers, artificial magnetic conductors</li>
                                    <li>Plasmonic and optical components, nanomaterials, and others</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
import React from 'react'
import Box from '@mui/material/Box';
import contact from './contactus.jpg'
import dummContact from './dummycontact.png'
import rang from './rangoli.jpg'

export default function() {
    return (
      <>
        <img src={dummContact} alt="" style={{backgroundSize: 'contain', width: '100%', height: '300px'}}/>
        {/* <div className='img-fluid'>
          <h1 className="text-center"><strong>CONTACT US</strong></h1>
        </div> */}
        {/* <div className='d-flex bd-highlight text-center'>
          <h1 className='img-fluid' style={{backgroundImage: `URL(${contact})`, backgroundSize: 'cover' ,height: '300px', width: '100%', justifyContent: 'center', textSizeAdjust: '50px', color: 'red', display: 'flex', alignItems: 'center', fontSize: '130px'}}><strong>CONTACT US</strong></h1>
        </div> */}
        {/* <div class="d-flex p-5 bd-highlight" style={{backgroundImage: `url(${contact})`, backgroundSize: 'contain', width: '100%'}}>
          <h1 className="text-center"><strong>CONTACT US</strong></h1>
        </div> */}
        {/* <div style={{ width: '100%', height: '100%' }}>
          <Box className='text-center p-5' sx={{ display: 'flex', backgroundImage: `url(${contact})`, backgroundSize: 'cover'}}>{"CONTACT US"}</Box>
        </div> */}
        <div className="container my-5 text-center" style={{padding: '3vh', transition: '3s', boxShadow: '0 4px 8px 0 rgba(0,0,0,.2)'}}>
          <h3 className='font-weight-bold'>Please Contact Us in Case of any Doubt</h3>
          <p className='my-4'><strong>Dr. Surbhi Sharma</strong></p>
          <p>+91 9815302660</p>
          <p>Email: surbhi.sharma@thapar.edu</p>
          <p className='my-3'><strong>Dr. Rajesh Khanna</strong></p>
          <p>+91 9872883263</p>
          <p>Email: rkhanna@thapar.edu</p>
        </div>
      </>
    )
}
import React from 'react'
import pkNair from './pkNair.png'
import ajb from './ab.jpg'
import rsk from './rsk.png'
import ss from './ss.jpg'
import rk from './rk.jpg'
import ks from './ks.jpg'
import backgrd from './background.jpg'

export default function() {
    return (
        <>
            {/* <div style={{backgroundImage: 'URL("")'}}>
                    <h1 className="text-center"><strong>ORGANIZING COMMITTEE</strong></h1>
            </div> */}
            <div className='d-flex bd-highlight text-center'>
                <h1 className='img-fluid' style={{backgroundImage: `URL(${backgrd})`, backgroundSize: 'cover' ,height: '300px', width: '100%', justifyContent: 'center', textSizeAdjust: '50px', color: 'white', display: 'flex', alignItems: 'center', fontSize: '60px'}}><strong>ORGANIZING COMMITTEE</strong></h1>
            </div>
            {/* <div className="row container offset-1 my-5" style={{width: '100%'}}>
                <div className="col-md-4">
                    <div className="card">
                        <h5 className="card-title text-center">CHIEF PATRON</h5>
                        <img className="card-img-top" style={{height: "20rem"}} src={pkNair} alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>Prof. Padamkumar Nair</strong></p>
                            <p className="card-text">Director, TIET</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card">
                        <h5 className="card-title text-center">PATRON</h5>
                        <img className="card-img-top" style={{height: "20rem"}} src={ajb} alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>Prof. Ajay Batish</strong></p>
                            <p className="card-text">Deputy Director, TIET</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card">
                        <h5 className="card-title text-center">CHAIRPERSON</h5>
                        <img className="card-img-top" style={{height: "20rem"}} src={rsk} alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>Dr. RS Kaler</strong></p>
                            <p className="card-text">DOFA, HECED (Ex-officio), TIET</p>
                        </div>
                    </div>
                </div>
            </div>
            <h3 className='text-center my-4'><strong>CONVENERS</strong></h3>
            <div className="row container offset-2" style={{width: '100%'}}>
                <div className="col-sm-4">
                    <div className="card">
                        <img className="card-img-top" style={{height: "20rem"}} src={ss} alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>Dr. Surbhi Sharma</strong></p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="card">
                        <img className="card-img-top" style={{height: "20rem"}} src={rk} alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>Dr. Rajesh Khanna</strong></p>
                        </div>
                    </div>
                </div>
            </div>
            <h3 className='text-center my-4'><strong>CO-CONVENERS</strong></h3>
            <div className="row container mx-auto" style={{width: '50rem'}}>
                <div className="col-sm-4">
                    <div className="card">
                        <img className="card-img-top" src="" alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>Dr. Kulbir Singh</strong></p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="card">
                        <img className="card-img-top" src="" alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>Dr. M.K Rai</strong></p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="card">
                        <img className="card-img-top" src="" alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>Dr Sandeep Kumar</strong></p>
                            <p className="card-text">NITK Surathkal</p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="card">
                        <img className="card-img-top" src="" alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>Dr. Parul Pathak</strong></p>
                            <p className="card-text">JECRC University, Jaipur</p>
                        </div>
                    </div>
                </div>
            </div>
            <h3 className='text-center my-4'><strong>ORGANIZING SECRETARY</strong></h3>
            <div className="row container mx-auto" style={{width: '50rem'}}>
                <div className="col-sm-4">
                    <div className="card">
                        <img className="card-img-top" src="" alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>Dr. Hari Shankar</strong></p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="card">
                        <img className="card-img-top" src="" alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>Dr. Amit Mishra</strong></p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="card">
                        <img className="card-img-top" src="" alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>Dr. Anil Arora</strong></p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="card">
                        <img className="card-img-top" src="" alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>Dr. Jaswinder Kaur</strong></p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="card">
                        <img className="card-img-top" src="" alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>Dr. Debyani Gosh</strong></p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="card">
                        <img className="card-img-top" src="" alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>Dr. Shashikant</strong></p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="card">
                        <img className="card-img-top" src="" alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>Dr. Dinesh</strong></p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="card">
                        <img className="card-img-top" src="" alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>Amit Munjal</strong></p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="card">
                        <img className="card-img-top" src="" alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>Geetika Dua</strong></p>
                        </div>
                    </div>
                </div>
            </div>
            <h3 className='text-center my-4'><strong>LIAISON</strong></h3>
            <div className="row container mx-auto" style={{width: '50rem'}}>
                <div className="col-sm-4">
                    <div className="card">
                        <img className="card-img-top" src="" alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>Dr. Vinay Kumar</strong></p>
                        </div>
                    </div>
                </div>
            </div>
            <h3 className='text-center my-4'><strong>REGISTRATION AND RECEPTION</strong></h3>
            <div className="row container mx-auto" style={{width: '50rem'}}>
                <div className="col-sm-4">
                    <div className="card">
                        <img className="card-img-top" src="" alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>Dr. Jaswinder Kaur</strong></p>
                        </div>
                    </div>
                </div>
            </div>
            <h3 className='text-center my-4'><strong>ACCOMMODATION AND TRANSPORT</strong></h3>
            <div className="row container mx-auto" style={{width: '50rem'}}>
                <div className="col-sm-4">
                    <div className="card">
                        <img className="card-img-top" src="" alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>Mr Vinod Sharma</strong></p>
                            <p className="card-text">Administration</p>
                        </div>
                    </div>
                </div>
            </div>
            <h3 className='text-center my-4'><strong>FOOD AND HOSPITALITY</strong></h3>
            <div className="row container mx-auto" style={{width: '50rem'}}>
                <div className="col-sm-4">
                    <div className="card">
                        <img className="card-img-top" src="" alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>Dr. Anil Arora</strong></p>
                        </div>
                    </div>
                </div>
            </div>
            <h3 className='text-center my-4'><strong>FINANCE AND ACCOUNTS</strong></h3>
            <div className="row container mx-auto" style={{width: '50rem'}}>
                <div className="col-sm-4">
                    <div className="card">
                        <img className="card-img-top" src="" alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>Dr. Hari Shankar</strong></p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="card">
                        <img className="card-img-top" src="" alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>Dr. Dinesh</strong></p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="card">
                        <img className="card-img-top" src="" alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>Dr. Shashikant</strong></p>
                        </div>
                    </div>
                </div>
            </div>
            <h3 className='text-center my-4'><strong>PURCHASE COMMITTEE</strong></h3>
            <div className="row container mx-auto" style={{width: '50rem'}}>
                <div className="col-sm-4">
                    <div className="card">
                        <img className="card-img-top" src="" alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>Dr. Amit Mishra</strong></p>
                        </div>
                    </div>
                </div>
            </div>
            <h3 className='text-center my-4'><strong>EDITORIAL TEAM</strong></h3>
            <div className="row container mx-auto" style={{width: '50rem'}}>
                <div className="col-sm-4">
                    <div className="card">
                        <img className="card-img-top" src="" alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>Dr. Sanyog Rawat</strong></p>
                            <p className="card-text">Central University of Rajasthan</p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="card">
                        <img className="card-img-top" src="" alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>Dr. Pramod Kumar</strong></p>
                            <p className="card-text">MAHE Manipal</p>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="container my-5" style={{width: "75%"}}>
                <div className="row row-cols-1 row-cols-md-2 g-4">
                    <div className="col">
                        <div className="card h-100 text-center">
                        <h3 className="card-title"><strong>CHIEF PATRON</strong></h3>
                        <img src={pkNair} className="card-img-top" alt="..." style={{height: "14rem"}}/>
                        <div className="card-body">
                        <p className="card-text"><strong>Padamkumar Nair</strong></p>
                            <p className="card-text">Director, TIET</p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center">
                        <h3 className="card-title"><strong>PATRON</strong></h3>
                        <img src={ajb} className="card-img-top" alt="..." style={{height: "14rem"}}/>
                        <div className="card-body">
                        <p className="card-text"><strong>Ajay Batish</strong></p>
                            <p className="card-text">Deputy Director, TIET</p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center">
                        <h3 className="card-title"><strong>PATRON</strong></h3>
                        <img src={rsk} className="card-img-top" alt="..." style={{height: "14rem"}}/>
                        <div className="card-body">
                        <p className="card-text"><strong>R.S. Kaler</strong></p>
                            <p className="card-text">Dean of Faculty Affairs, TIET</p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center">
                        <h3 className="card-title"><strong>CHAIRPERSON</strong></h3>
                        <img src={ks} className="card-img-top" alt="..." style={{height: "14rem"}}/>
                        <div className="card-body">
                        <p className="card-text"><strong>Kulbir Singh</strong></p>
                            <p className="card-text">Professor and Head ECE, TIET</p>
                        </div>
                        </div>
                    </div>
                </div>
                <h3 className='text-center my-4'><strong>ORGANIZING CHAIRS</strong></h3>
                <div className="row row-cols-1 row-cols-md-2 g-4">
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="" className="card-img-top" alt="..."/> */}
                        <div className="card-body">
                        <p className="card-text"><strong>Surbhi Sharma</strong></p>
                        <p className="card-text">TIET, Patiala</p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="" className="card-img-top" alt="..."/> */}
                        <div className="card-body">
                        <p className="card-text"><strong>Rajesh Khanna</strong></p>
                        <p className="card-text">TIET, Patiala</p>
                        </div>
                        </div>
                    </div>
                </div>
                <h3 className='text-center my-4'><strong>CONVENERS</strong></h3>
                <div className="row row-cols-1 row-cols-md-3 g-4">
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="" className="card-img-top" alt="..."/> */}
                        <div className="card-body">
                        <p className="card-text"><strong>Sanyog Rawat</strong></p>
                        <p className="card-text">Central University of Rajasthan</p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="" className="card-img-top" alt="..."/> */}
                        <div className="card-body">
                        <p className="card-text"><strong>M.K Rai</strong></p>
                        <p className="card-text">TIET, Patiala</p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="" className="card-img-top" alt="..."/> */}
                        <div className="card-body">
                        <p className="card-text"><strong>Praveen Agarwal</strong></p>
                        <p className="card-text">AICE, Jaipur</p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="" className="card-img-top" alt="..."/> */}
                        <div className="card-body">
                        <p className="card-text"><strong>Ritu Sharma</strong></p>
                        <p className="card-text">MNIT Jaipur</p>
                        </div>
                        </div>
                    </div>
                    {/* <div className="col">
                        <div className="card h-100 text-center"> */}
                        {/* <img src="" className="card-img-top" alt="..."/> */}
                        {/* <div className="card-body">
                        <p className="card-text"><strong>Dr Sandeep Kumar</strong></p>
                        <p className="card-text">NITK Surathkal</p>
                        </div>
                        </div>
                    </div> */}
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="" className="card-img-top" alt="..."/> */}
                        <div className="card-body">
                        <p className="card-text"><strong>Parul Pathak</strong></p>
                        <p className="card-text">JECRC University, Jaipur</p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="" className="card-img-top" alt="..."/> */}
                        <div className="card-body">
                        <p className="card-text"><strong>Pramod Kumar</strong></p>
                        <p className="card-text">MAHE, Manipal</p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="" className="card-img-top" alt="..."/> */}
                        <div className="card-body">
                        <p className="card-text"><strong>Priyanka Chawla</strong></p>
                        <p className="card-text">NIT Warangal</p>
                        </div>
                        </div>
                    </div>
                </div>
                <h3 className='text-center my-4'><strong>Co - CONVENERS</strong></h3>
                <div className="row row-cols-1 row-cols-md-3 g-4">
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="" className="card-img-top" alt="..."/> */}
                        <div className="card-body">
                        <p className="card-text"><strong>Pradeep Kumar</strong></p>
                        <p className="card-text">University of KwaZulu-Natal, South Africa </p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="" className="card-img-top" alt="..."/> */}
                        <div className="card-body">
                        <p className="card-text"><strong>Vijander Singh</strong></p>
                        <p className="card-text">NTNU, Norway</p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="" className="card-img-top" alt="..."/> */}
                        <div className="card-body">
                        <p className="card-text"><strong>Shally Goyal</strong></p>
                        <p className="card-text">Amity University, Madhya Pradesh</p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="" className="card-img-top" alt="..."/> */}
                        <div className="card-body">
                        <p className="card-text"><strong>Madhu Sharma</strong></p>
                        <p className="card-text">DIT University, Dehradun</p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="" className="card-img-top" alt="..."/> */}
                        <div className="card-body">
                        <p className="card-text"><strong>Sandeep Kumar</strong></p>
                        <p className="card-text">NITK Surathkal</p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="" className="card-img-top" alt="..."/> */}
                        <div className="card-body">
                        <p className="card-text"><strong>Shilpi Jain</strong></p>
                        <p className="card-text">Poornima College of Engineering, Jaipur</p>
                        </div>
                        </div>
                    </div>
                </div>
                <h3 className='text-center my-4'><strong>ORGANIZING SECRETARIES</strong></h3>
                <div className="row row-cols-1 row-cols-md-3 g-4">
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="" className="card-img-top" alt="..."/> */}
                        <div className="card-body">
                        <p className="card-text"><strong>Hari Shankar</strong></p>
                        <p className="card-text">TIET, Patiala</p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="" className="card-img-top" alt="..."/> */}
                        <div className="card-body">
                        <p className="card-text"><strong>Amit Mishra</strong></p>
                        <p className="card-text">TIET, Patiala</p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="" className="card-img-top" alt="..."/> */}
                        <div className="card-body">
                        <p className="card-text"><strong>Anil Arora</strong></p>
                        <p className="card-text">TIET, Patiala</p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="" className="card-img-top" alt="..."/> */}
                        <div className="card-body">
                        <p className="card-text"><strong>Jaswinder Kaur</strong></p>
                        <p className="card-text">TIET, Patiala</p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="" className="card-img-top" alt="..."/> */}
                        <div className="card-body">
                        <p className="card-text"><strong>Debyani Ghosh</strong></p>
                        <p className="card-text">TIET, Patiala</p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="" className="card-img-top" alt="..."/> */}
                        <div className="card-body">
                        <p className="card-text"><strong>Shashikant</strong></p>
                        <p className="card-text">TIET, Patiala</p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="" className="card-img-top" alt="..."/> */}
                        <div className="card-body">
                        <p className="card-text"><strong>Dinesh</strong></p>
                        <p className="card-text">TIET, Patiala</p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="" className="card-img-top" alt="..."/> */}
                        <div className="card-body">
                        <p className="card-text"><strong>Amit Munjal</strong></p>
                        <p className="card-text">TIET, Patiala</p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="" className="card-img-top" alt="..."/> */}
                        <div className="card-body">
                        <p className="card-text"><strong>Geetika Dua</strong></p>
                        <p className="card-text">TIET, Patiala</p>
                        </div>
                        </div>
                    </div>
                </div>
                <h3 className='text-center my-4'><strong>JOINT ORGANIZING SECRETARIES</strong></h3>
                <div className="row row-cols-1 row-cols-md-3 g-4">
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="" className="card-img-top" alt="..."/> */}
                        <div className="card-body">
                        <p className="card-text"><strong>Vishal Das,</strong></p>
                        <p className="card-text">Manipal University Jaipur</p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="" className="card-img-top" alt="..."/> */}
                        <div className="card-body">
                        <p className="card-text"><strong>Tapan Nahar</strong></p>
                        <p className="card-text">Marwadi University Rajkot</p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="" className="card-img-top" alt="..."/> */}
                        <div className="card-body">
                        <p className="card-text"><strong>Pallav Rawal</strong></p>
                        <p className="card-text">SKIT, Jaipur</p>
                        </div>
                        </div>
                    </div>
                </div>
                {/* <h3 className='text-center my-4'><strong>LIAISON</strong></h3>
                <div className="row row-cols-1 row-cols-md-2 g-4">
                    <div className="col">
                        <div className="card h-100 text-center"> */}
                        {/* <img src="" className="card-img-top" alt="..."/> */}
                        {/* <div className="card-body">
                        <p className="card-text"><strong>Vinay Kumar</strong></p>
                        <p className="card-text">TIET, Patiala</p>
                        </div>
                        </div>
                    </div>
                </div> */}
                {/* <h3 className='text-center my-4'><strong>REGISTRATION AND RECEPTION</strong></h3>
                <div className="row row-cols-1 row-cols-md-2 g-4">
                    <div className="col">
                        <div className="card h-100 text-center"> */}
                        {/* <img src="" className="card-img-top" alt="..."/> */}
                        {/* <div className="card-body">
                        <p className="card-text"><strong>Jaswinder Kaur</strong></p>
                        <p className="card-text">TIET, Patiala</p>
                        </div>
                        </div>
                    </div>
                </div> */}
                {/* <h3 className='text-center my-4'><strong>ACCOMMODATION AND TRANSPORT</strong></h3>
                <div className="row row-cols-1 row-cols-md-2 g-4">
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="" className="card-img-top" alt="..."/> */}
                        {/* <div className="card-body">
                        <p className="card-text"><strong>Vinod Sharma</strong></p>
                        <p className="card-text">Administration</p>
                        </div>
                        </div>
                    </div>
                </div> */}
                {/* <h3 className='text-center my-4'><strong>FOOD AND HOSPITALITY</strong></h3>
                <div className="row row-cols-1 row-cols-md-2 g-4">
                    <div className="col">
                        <div className="card h-100 text-center"> */}
                        {/* <img src="" className="card-img-top" alt="..."/> */}
                        {/* <div className="card-body">
                        <p className="card-text"><strong>Anil Arora</strong></p>
                        <p className="card-text">TIET, Patiala</p>
                        </div>
                        </div>
                    </div>
                </div> */}
                {/* <h3 className='text-center my-4'><strong>FINANCE AND ACCOUNTS</strong></h3>
                <div className="row row-cols-1 row-cols-md-3 g-4">
                    <div className="col">
                        <div className="card h-100 text-center"> */}
                        {/* <img src="" className="card-img-top" alt="..."/> */}
                        {/* <div className="card-body">
                        <p className="card-text"><strong>Hari Shankar</strong></p>
                        <p className="card-text">TIET, Patiala</p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center"> */}
                        {/* <img src="" className="card-img-top" alt="..."/> */}
                        {/* <div className="card-body">
                        <p className="card-text"><strong>Dinesh</strong></p>
                        <p className="card-text">TIET, Patiala</p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center"> */}
                        {/* <img src="" className="card-img-top" alt="..."/> */}
                        {/* <div className="card-body">
                        <p className="card-text"><strong>Shashikant</strong></p>
                        <p className="card-text">TIET, Patiala</p>
                        </div>
                        </div>
                    </div> */}
                {/* </div> */}
                {/* <h3 className='text-center my-4'><strong>PURCHASE COMMITTEE</strong></h3>
                <div className="row row-cols-1 row-cols-md-2 g-4">
                    <div className="col">
                        <div className="card h-100 text-center"> */}
                        {/* <img src="" className="card-img-top" alt="..."/> */}
                        {/* <div className="card-body">
                        <p className="card-text"><strong>Amit Mishra</strong></p>
                        <p className="card-text">TIET, Patiala</p>
                        </div>
                        </div>
                    </div>
                </div> */}
                {/* <h3 className='text-center my-4'><strong>EDITORIAL TEAM</strong></h3>
                <div className="row row-cols-1 row-cols-md-2 g-4">
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="" className="card-img-top" alt="..."/> */}
                        {/* <div className="card-body">
                        <p className="card-text"><strong>Sanyog Rawat</strong></p>
                        <p className="card-text">Central University of Rajasthan</p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center"> */}
                        {/* <img src="" className="card-img-top" alt="..."/> */}
                        {/* <div className="card-body">
                        <p className="card-text"><strong>Pramod Kumar</strong></p>
                        <p className="card-text">MAHE Manipal</p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center"> */}
                        {/* <img src="" className="card-img-top" alt="..."/> */}
                        {/* <div className="card-body">
                        <p className="card-text"><strong>Surbhi Sharma</strong></p>
                        <p className="card-text">TIET, Patiala</p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center"> */}
                        {/* <img src="" className="card-img-top" alt="..."/> */}
                        {/* <div className="card-body">
                        <p className="card-text"><strong>Dinesh</strong></p>
                        <p className="card-text">TIET, Patiala</p>
                        </div>
                        </div>
                    </div> */}
                {/* </div> */}
            </div>
        </>
    )
}
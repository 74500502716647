import React from 'react'
import Box from '@mui/material/Box';
import backgrd from './background.jpg'
import dukh1 from './dukhnivaran1.jpeg'
import dukh2 from './dukhnivaran2.jpeg'

export default function() {
    return (
      <>
       <div className='d-flex bd-highlight text-center'>
            <h1 className='img-fluid' style={{backgroundImage: `URL(${backgrd})`, backgroundSize: 'cover' ,height: '350px', width: '100%', justifyContent: 'center', textSizeAdjust: '50px', color: 'white', display: 'flex', alignItems: 'center', fontSize: '100px'}}><strong>NEAR BY PLACES</strong></h1>
        </div>
        <div className="container my-4">
          {/* <img src={dukh1} alt="" className="img-fluid rounded float-left" />
          <img src={dukh2} alt="" className="img-fluid rounded float-end" /> */}
          <h3 className='text-center' style={{fontWeight: 'bold'}}>Gurdwara Dukhniwaran Sahib, Patiala</h3>
          <div class="row mx-auto my-2">
            <div class="col">
              <img className="text-center img-fluid rounded float-start" alt="Springer" src={dukh1}/>
            </div>
            <div class="col">
              <img className="text-center img-fluid rounded float-end" alt="Springer" src={dukh2}/>
            </div>
          </div>
          <p style={{textAlign: 'justify'}}>As per and old handwritten document which is still present in the Gurudwara, a villager named Bhag Ram of Lehal went to the neighboring town of Saifabad (currently Bahadurgarh) and requested Shri Guru Sri Tegh Bahadur Ji to visit Lehal village and bless that area which was captivated by a deadly and mysterious disease. After hearing this, Shri Guru Sri Tegh Bahadur Ji went to Lehal village on Magh Sudi 5, 1728 Bikram i.e. 24th January 1672. Guru ji spent some time alongside the village pond by preaching and meditating under a Banyan tree. Shortly thereafter the village got rid of that deadly disease. Dukniwaran Sahib was the name given to the place where Guru Ji sat which literally means the ‘eradicator of suffering’. The pond became famous for its curing virtues.</p>
        </div>
      </>
    )
}
import './App.css';
import React, {useState} from 'react';
import Navbar from './components/Navbar';
import thapar2 from './thapar2.jpg'
import About from './components/about';
import Grid from './components/grid';
import Footer from './components/footer';
import ContactUs from './components/contactUs';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Routes
} from "react-router-dom";
import TechCommittee from './components/techCommittee';
import OrgCommittee from './components/orgCommittee';
import Tracks from './components/tracks';
import AdvCommittee from './components/advCommittee';
import StuCommittee from './components/stuCommittee';
import ImpDates from './components/impDates';
import springer from './springer.png'
import scopus from './scopus.png'
import KeynoteSpeakers from './components/keynoteSpeakers';
import Publications from './components/publications';
import ProgramSchedule from './components/programSchedule';
import HowToReach from './components/howToReach';
import AboutTiet from './components/aboutTiet';
import NearByPlaces from './components/nearByPlaces';
import Gallery from './components/gallery';
import Registration from './components/registration';

function App(){
  return (
    <>
    <Router>
      <Navbar/>
      <p>
      {/* <a href="https://cmt3.research.microsoft.com/">hello</a> */}
      </p>
      <Routes>
        <Route exact path="/contactUs" element={<ContactUs/>}>
        </Route>
        {/* <Route exact path='/' element={<><img src={thapar2} className="img-fluid" alt="Responsive image"></img><div className='my-3 text-center'><button type="button" class="btn btn-primary btn-lg" style={{animation: '3s linear 1s infinite alternate slidein'}}>Registration Link</button></div><About/><Grid/><GoogleMaps lat={30.350243} lng={76.360243} text="My Marker"/></>}></Route> */}
        <Route exact path='/' element={<>
              {/* <div className='d-flex bd-highlight text-center'>
                <h1 className='img-fluid' style={{backgroundImage: `URL(${thapar2})`, backgroundSize: 'cover' ,height: '600px', width: '100%', justifyContent: 'center', textSizeAdjust: '50px', color: '#db5330', display: 'flex', alignItems: 'center', fontSize: '50px'}}><strong>IAICCTSD-2024 <p className='my-4'>1st International Conferene on Advances in Information Communication, and Computational Technologies for Sustainable Development</p></strong></h1>
              </div> */}
               <div className="d-flex bd-highlight p-3 mb-2 text-danger" style={{backgroundColor: '#fff8e7'}}>
                <div className="p-2 w-100 bd-highlight text-center">
                <h1><strong>IAICCTSD-2024</strong></h1> <h3 className='my-4'>1st International Conferene on Advances in Information Communication, and Computational Technologies for Sustainable Development</h3>
                <h2><strong>22nd - 23rd November, 2024</strong></h2>
                </div>
                <div className="p-2 flex-shrink-1 bd-highlight">
                    <img src={thapar2} className='w-100 h-100' alt="" />
                </div>
            </div>
            <div className='my-3 text-center'><button type="button" className="btn btn-primary btn-lg disabled" style={{animation: '3s linear 1s infinite alternate slidein'}}>Registration Link</button></div><About/><Grid/></>}></Route>
        <Route exact path='/techComm' element={<TechCommittee/>}></Route>
        <Route exact path='/orgComm' element={<OrgCommittee/>}></Route>
        <Route exact path='/tracks' element={<Tracks/>}></Route>
        <Route exact path='/advComm' element={<AdvCommittee/>}></Route>
        <Route exact path='/stuComm' element={<StuCommittee/>}></Route>
        <Route exact path='/impDates' element={<ImpDates/>}></Route>
        <Route exact path='/keynote' element={<KeynoteSpeakers/>}></Route>
        <Route exact path='/publications' element={<Publications/>}></Route>
        <Route exact path='/program' element={<ProgramSchedule/>}></Route>
        <Route exact path='/how' element={<HowToReach/>}></Route>
        <Route exact path='/abouttiet' element={<AboutTiet/>}></Route>
        <Route exact path='/gallery' element={<Gallery/>}></Route>
        <Route exact path='/near' element={<NearByPlaces/>}></Route>
        <Route exact path='/registration' element={<Registration/>}></Route>
        {/* <Route exact path='/orgComm' element={<Org3/>}></Route> */}

      </Routes>
      {/* <div class="p-3 mb-0 my-3" style={{height: "10rem", backgroundColor: "#097986"}}> */}
        {/* <table className="center highlight container">
          <tbody>
            <tr>
              <td className="center"><img class="springer-partner" alt="Springer" src={springer}/></td>
              <td><img className="springer-partner" alt="Springer" src={scopus}/></td>
            </tr>
          </tbody>
        </table> */}
        {/* <table className="table" style={{backgroundColor: "#097986"}}>
          <thead>
            <tr>
              <th scope="col" style={{backgroundColor: "#097986"}}></th>
              <th scope="col" style={{backgroundColor: "#097986"}}></th>
              <th scope="col" style={{backgroundColor: "#097986"}}></th>
              <th scope="col" style={{backgroundColor: "#097986"}}></th>
              <th scope="col" style={{backgroundColor: "#097986"}}></th>
              <th scope="col" style={{backgroundColor: "#097986"}}></th>
              <th scope="col" style={{backgroundColor: "#097986", verticalAlign: 'middle'}}><img className="text-center" alt="Springer" src={springer} style={{height: "7rem", backgroundColor: "#097986"}}/></th>
              <th scope="col" style={{backgroundColor: "#097986", verticalAlign: 'middle'}}><img className="text-center" alt="Springer" src={scopus} style={{height: "7rem", backgroundColor: "#097986"}}/></th>
            </tr>
          </thead>
        </table>   */}
        {/* 
          <img className="text-center ml-20" alt="Springer" src={springer} style={{height: "7rem", backgroundColor: "#097986"}}/>
          <img className="text-center" alt="Springer" src={scopus} style={{height: "7rem", backgroundColor: "#097986"}}/>
      </div> */}
      <div className="p-3 mb-0 my-3" style={{height: "10rem", backgroundColor: "#097986"}}>
        {/* <div className="row mx-auto">
          <div className="col">
          <img className="text-center img-fluid float-start" alt="Springer" src={springer} style={{height: "7rem", backgroundColor: "#097986"}}/>
          </div>
          <div className="col">
          <img className="text-center img-fluid float-end" alt="Springer" src={scopus} style={{height: "7rem", backgroundColor: "#097986"}}/>
          </div>
          <p className='text-warning'>(Waiting For Approval)</p>
        </div> */}
      </div>
      <Footer/>
    </Router>
    </>
  );
}
export default App;

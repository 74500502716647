import React from 'react'
import Box from '@mui/material/Box';
import backgrd from './background.jpg'
import GoogleMaps from './googleMaps';

export default function() {
    return (
      <>
       <div className='d-flex bd-highlight text-center'>
            <h1 className='img-fluid' style={{backgroundImage: `URL(${backgrd})`, backgroundSize: 'cover' ,height: '300px', width: '100%', justifyContent: 'center', textSizeAdjust: '50px', color: 'white', display: 'flex', alignItems: 'center', fontSize: '100px'}}><strong>HOW TO REACH</strong></h1>
        </div>
        <div className="container">
          <h3 className='my-3 text-center'>THE ROYAL CITY PATIALA</h3>
          <p className='mb-4' style={{textAlign: 'justify'}}>Patiala is a city in south-eastern Punjab, north-western India. It is the state's fourth-largest city.  Patiala is the erstwhile princely state of Punjab, which is situated in the Malwa region. It is a judicious  synthesis of a brilliant spectrum of Rajput, Mughal, and Punjabi cultures and a fine blend of  modernity and tradition. It is well connected to Delhi, Ambala, and Chandigarh by rail and road. It is  around 280 Km from IGI Airport Delhi and 60Km from Chandigarh Airport. Shimla, Dharamshala,  Manali, and Amritsar are nearby cities of attraction.</p>
          {/* <GoogleMaps lat={30.3564} lng={76.3647} text="My Marker"/> */}
        </div>
      </>
    )
}
import React from 'react'
import globe from './globe.jpg';

export default function() {
    return (
        <div className='container my-5'>
            {/* <h1>About IAICCT-2024</h1> */}
        {/* //     <p style={{textAlign: 'justify'}}>The conference is concerned with the application of the latest computational technology to all aspects of Electronics Engineering and Wireless Communications. Computational technology encompasses both the latest hardware and software developments as well as algorithmic and theoretical developments. The conference is concerned with both theoretical, mathematical and scientific developments as well as applications of established technology to new domains. The purpose of holding this conference will aim at bringing together from around the world academics, researchers and practitioners to review recent achievements in the advancement of knowledge and understanding in these areas, share the latest developments, and address the challenges that the present and the future pose.</p> */}
        



            <div className="d-flex bd-highlight">
                <div className="p-2 w-100 bd-highlight">
                <h1>About IAICCTSD-2024</h1>
                <p style={{textAlign: 'justify'}}>The conference is concerned with the application of the latest computational technology to all aspects of Electronics Engineering and Wireless Communications. Computational technology encompasses both the latest hardware and software developments as well as algorithmic and theoretical developments. The conference is concerned with both theoretical, mathematical and scientific developments as well as applications of established technology to new domains. The purpose of holding this conference will aim at bringing together from around the world academics, researchers and practitioners to review recent achievements in the advancement of knowledge and understanding in these areas, share the latest developments, and address the challenges that the present and the future pose.</p>
                </div>
                <div className="p-2 flex-shrink-1 bd-highlight">
                    <img src={globe} className='w-100 h-auto' alt="" />
                </div>
            </div>
        </div>
    )
}

import React, { Component } from 'react'
import publication from './publication.png';

export default function() {
    return (
        <div className="grid container">
            <div className="row" style={{gridGap: '10px'}}>
                {/* <div className="content-box rounded col-sm border-dark" id="item-1" style={{backgroundColor: "#8cdeee"}}>
                    <ul>
                        <h2 className="text-center my-3"><strong>SUBMISSION</strong></h2>
                        <p style={{textAlign: 'justify'}} className="space">Paper submissions are invited in the area of Electronics and Wireless Communication Systems.</p>
                        <h5><strong>Paper Submission Guidlines:</strong></h5>
                        <p style={{textAlign: 'justify'}} className="space">Papers must be submitted electronically in a single-column springer format (8 to max 10 pages), templates ).
                            Submitted papers will undergo a double-blind peer-review process, coordinated by the Technical Program Committee.
                            Authors should consult Springer’s authors’ guidelines and use their proceedings templates, either for LaTeX or for Word, for the preparation of their papers.
                            Springer encourages authors to include their ORCIDs in their papers.</p>
                        <h5><strong>Note for full paper submissions:</strong></h5>
                        <p style={{textAlign: 'justify'}} className="space">All papers must be submitted electronically in PDF and DOC format in the Springer style.
                            Although we accept submissions in the form of PDF, PS, and DOC/RTF files, you are strongly encouraged to generate a PDF version for your paper before submitting.</p>
                    </ul>
                </div> */}
                <div className="content-box rounded col-sm border-dark" id="item-1" style={{backgroundColor: "#8cdeee"}}>
                    <ul>
                        <h2 className="text-center my-3"><strong>SUBMISSION</strong></h2>
                        <p style={{textAlign: 'justify'}} className="space">Paper submissions are invited in the area of Electronics and Wireless Communication Systems.</p>
                        <h5><strong>Paper Submission Guidlines:</strong></h5>
                        <p style={{textAlign: 'justify'}} className="space">Papers must be submitted electronically in a single-column standard IEEE format (8 to max 10 pages), templates ).
                            Submitted papers will undergo a double-blind peer-review process, coordinated by the Technical Program Committee.
                            Authors should consult IEEE's author's guidelines and use their proceedings templates, either for LaTeX or for Word, for the preparation of their papers.
                            IEEE encourages authors to include their ORCIDs in their papers.</p>
                        <h5><strong>Note for full paper submissions:</strong></h5>
                        <p style={{textAlign: 'justify'}} className="space">All papers must be submitted electronically in PDF and DOC format in the standard IEEE style.
                            Although we accept submissions in the form of PDF, PS, and DOC/RTF files, you are strongly encouraged to generate a PDF version for your paper before submitting.</p>
                    </ul>
                </div>
                {/* <div className="content-box rounded col-sm border-dark" id="item-2" style={{backgroundColor: "#8cdeee"}}>
                    <ul>
                        <h2 className="text-center my-3"><strong>PUBLICATION</strong></h2>
                        <p style={{textAlign: 'justify'}} className="space">Proceedings of IAICCT 2024 will be published in scopus indexed book series: “”.</p> */}
                        {/* <img src={publication} alt="" className="rounded mx-auto d-block"/> */}
                        {/* <h5><strong>Best Student Paper contest:</strong></h5>
                        <p style={{textAlign: 'justify'}} className="space">Best student paper awards will be presented based on the decision of steering committee depending on originality, significance, technical soundness, and presentation. To qualify for the award, the author must be registered as a student and must present their work during presentation.</p>
                    </ul>
                </div> */}
                <div className="content-box rounded col-sm border-dark" id="item-2" style={{backgroundColor: "#8cdeee"}}>
                    <ul>
                        <h2 className="text-center my-3"><strong>PUBLICATION</strong></h2>
                        <p style={{textAlign: 'justify'}} className="space">Proceedings of IAICCT 2024 will be published in IEEE indexed book series: “”.</p>
                        {/* <img src={publication} alt="" className="rounded mx-auto d-block"/> */}
                        <h5><strong>Best Student Paper contest:</strong></h5>
                        <p style={{textAlign: 'justify'}} className="space">Best student paper awards will be presented based on the decision of steering committee depending on originality, significance, technical soundness, and presentation. To qualify for the award, the author must be registered as a student and must present their work during presentation.</p>
                    </ul>
                </div>
                <div className="content-box rounded col-sm border-dark" style={{backgroundColor: "#8cdeee"}}>
                    <h2 className="text-center my-3"><strong>IMPORTANT DATES</strong></h2>
                    <ul>
                        <li>
                            <strong>Last date of paper submission:</strong>
                            <p style={{color: 'red'}}><strong>31st July, 2024</strong></p>
                        </li>
                        <li>
                            <strong>Notification of paper acceptance:</strong>
                            <p style={{color: 'red'}}><strong>15th October, 2024</strong></p>
                        </li>
                        <li>
                            <strong>Camera ready paper submission:</strong>
                            <p style={{color: 'red'}}><strong>31st October, 2024</strong></p>
                        </li>
                        <li>
                            <strong>Last date of early bird registration:</strong>
                            <p style={{color: 'red'}}><strong>7th November, 2024</strong></p>
                        </li>
                        <li>
                            <strong>Last date of registration:</strong>
                            <p style={{color: 'red'}}><strong>18th November, 2024</strong></p>
                        </li>
                        <li>
                            <strong>Conference date:</strong>
                            <p style={{color: 'red'}}><strong>22nd - 23rd November, 2024</strong></p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>        
    )
}

import React from 'react'
import backgrd from './background.jpg'

export default function() {
    return (
      <>
       <div className='d-flex bd-highlight text-center'>
            <h1 className='img-fluid' style={{backgroundImage: `URL(${backgrd})`, backgroundSize: 'cover' ,height: '300px', width: '100%', justifyContent: 'center', textSizeAdjust: '50px', color: 'white', display: 'flex', alignItems: 'center', fontSize: '65px'}}><strong>PUBLICATIONS</strong></h1>
        </div>
        {/* <p className='container' style={{animation: 'glowing 1300ms infinite', height: '150px', fontSize: '100px', textAlign: 'center'}}>TBD</p> */}
        <div className="container my-5 w-auto">
                  <div className="row row-cols-1 row-cols-md-1 g-4">
                    <div className="col">
                        <div className="card h-100 text-center">
                          <div className="card-body" style={{backgroundColor: "#4bd9e8"}}>
                              <ul className="card-title">
                                <p className='fw-bold fs-5'>PROPOSAL IS SUBMITTED IN THE BOOK SERIES IN SPRINGER (SUBMITTED FOR APPROVAL)</p>
                              </ul>
                          </div>
                        </div>
                    </div>
                  </div>
        </div>
        <div className="container">
          <div class="row row-cols-1 row-cols-md-1 g-4 my-3">
                      <div class="col">
                          <div class="card h-100 bg-light text-dark">
                              <div class="card-body" style={{backgroundColor: "#8fecf5"}}>
                                <h1 className='text-center'><strong>Our Convention's Alignment with Sustainable Development Goals</strong></h1>
                                {/* <section>
                                  <div class="row1">
                                    <div class="col-md-4 col-sm-6 col-xs-12">
                                      <div class="card1">
                                        <div class="cover item-a">
                                          <h1>Little<br/>Bonsai</h1>
                                          <span class="price">$79</span>
                                          <div class="card-back">
                                            <a href="#">Add to cart</a>
                                            <a href="#">View detail</a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-4 col-sm-6 col-xs-12">
                                      <div class="card1">
                                        <div class="cover item-b">
                                          <h1>Tropical<br/>Leaf</h1>
                                          <span class="price">$35</span>
                                          <div class="card-back">
                                            <a href="#">Add to cart</a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-4 col-sm-6 col-xs-12">
                                      <div class="card1">
                                        <div class="cover item-c">
                                          <h1>Marijuana<br/>Chill</h1>
                                          <span class="price">$155</span>
                                          <div class="card-back">
                                            <a href="#">Add to cart</a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section> */}
                                <div className="container my-5" style={{width: "75%"}}>
                                    <div class="row row-cols-1 row-cols-md-3 g-4">
                                        <div class="col">
                                            <div class="card h-100 text-center">
                                                {/* <img src="..." class="card-img-top" alt="..."/> */}
                                                <div class="card-body">
                                                    <h5 class="card-title"><strong>Quality Education</strong></h5>
                                                    <p class="card-text">Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="card h-100 text-center">
                                                {/* <img src="..." class="card-img-top" alt="..."/> */}
                                                <div class="card-body">
                                                    <h5 class="card-title"><strong>Affordable and Clean Energy</strong></h5>
                                                    <p class="card-text">Ensure access to affordable, reliable, sustainable and modern energy for all.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="card h-100 text-center">
                                                {/* <img src="..." class="card-img-top" alt="..."/> */}
                                                <div class="card-body">
                                                    <h5 class="card-title"><strong>Decent Work and Economic Growth</strong></h5>
                                                    <p class="card-text">Promote sustained, inclusive and sustainable economic growth, full and productive employment and decent work for all.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="card h-100 text-center">
                                                {/* <img src="..." class="card-img-top" alt="..."/> */}
                                                <div class="card-body">
                                                    <h5 class="card-title"><strong>Industry, Innovation and Infrastructure</strong></h5>
                                                    <p class="card-text">Build resilient infrastructure, promote inclusive and sustainable industrialization and foster innovation.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="card h-100 text-center">
                                                {/* <img src="..." class="card-img-top" alt="..."/> */}
                                                <div class="card-body">
                                                    <h5 class="card-title"><strong>Sustainable and Communities</strong></h5>
                                                    <p class="card-text">Make cities and human settlements inclusive, safe, resilient and sustainable.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="card h-100 text-center">
                                                {/* <img src="..." class="card-img-top" alt="..."/> */}
                                                <div class="card-body">
                                                    <h5 class="card-title"><strong>Partnerships for The Goals</strong></h5>
                                                    <p class="card-text">Strengthen the means of implementation and revitalize the Global Partnership for Sustainable Development.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              </div>
                          </div>
                      </div>
          </div>
        </div>
        
      </>
    )
}
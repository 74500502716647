import { hover } from '@testing-library/user-event/dist/hover'
import React from 'react'
import backgrd from './background.jpg'

export default function() {
    return (
        <>
            {/* <div className='img-fluid'>
                <h1 className="text-center"><strong>IMPORTANT DATES</strong></h1>
            </div> */}
            <div className='d-flex bd-highlight text-center'>
                <h1 className='img-fluid' style={{backgroundImage: `URL(${backgrd})`, backgroundSize: 'cover' ,height: '300px', width: '100%', justifyContent: 'center', textSizeAdjust: '50px', color: 'white', display: 'flex', alignItems: 'center', fontSize: '70px'}}><strong>IMPORTANT DATES</strong></h1>
            </div>
            <div className="container container-1 my-5" style={{padding: '3vh', transition: '3s', boxShadow: '0 4px 8px 0 rgba(0,0,0,.2)'}}>
                <table className="table table-striped text-center hoverable">
                    <thead>
                        <tr>
                        <th scope="col" style={{backgroundColor: '#06b3b3'}}>EVENT</th>
                        <th scope="col" style={{backgroundColor: '#06b3b3'}}>DATE</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td>Last date of paper submission:</td>
                        <td>31st July, 2024</td>
                        </tr>
                        <tr>
                        <td>Notification of paper acceptance:</td>
                        <td>15th October, 2024</td>
                        </tr>
                        <tr>
                        <td>Camera ready paper submission:</td>
                        <td>31st October, 2024</td>
                        </tr>
                        <tr>
                        <td>Last date of early bird registration:</td>
                        <td>7th November, 2024</td>
                        </tr>
                        <tr>
                        <td>Last date of registration:</td>
                        <td>18th November, 2024</td>
                        </tr>
                        <tr>
                        <td>Conference date:</td>
                        <td>22nd - 23rd November, 2024</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}
import React from 'react'
import backgrd from './background.jpg'

export default function() {
    return (
        <>
            {/* <div className='img-fluid'>
                <h1 className="text-center"><strong>ADVISORY COMMITTEE</strong></h1>
            </div> */}
            <div className='d-flex bd-highlight text-center'>
                <h1 className='img-fluid' style={{backgroundImage: `URL(${backgrd})`, backgroundSize: 'cover' ,height: '300px', width: '100%', justifyContent: 'center', textSizeAdjust: '50px', color: 'white', display: 'flex', alignItems: 'center', fontSize: '70px'}}><strong>ADVISORY COMMITTEE</strong></h1>
            </div>
            {/* <h3 className='text-center my-4'><strong>NATIONAL ADVISORY COMMITTEE</strong></h3>
            <div className="row container mx-auto" style={{width: '50rem'}}>
                <div className="col-sm-4">
                    <div className="card">
                        <img className="card-img-top" src="" alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>MV Kartikey</strong></p>
                            <p className="card-text">Director, IIITDM kanchipuram</p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="card">
                        <img className="card-img-top" src="" alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>Harish Kumar Sardana</strong></p>
                            <p className="card-text">Director, IIIT Raichur</p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="card">
                        <img className="card-img-top" src="" alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>CC Tripthati</strong></p>
                            <p className="card-text">Director, NITTR Bhopal</p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="card">
                        <img className="card-img-top" src="" alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>Vimal Bhatia</strong></p>
                            <p className="card-text">Associate professor, IIT indore</p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="card">
                        <img className="card-img-top" src="" alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>Dr. Ram Bilas Pachori</strong></p>
                            <p className="card-text">Professor, IIT Indore</p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="card">
                        <img className="card-img-top" src="" alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>Dr. Ajitha Shenoy K B</strong></p>
                            <p className="card-text">Manipal Institute of Technology Manipal, Manipal Academy of Higher Education</p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="card">
                        <img className="card-img-top" src="" alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>Dr. Preetam Kumar</strong></p>
                            <p className="card-text">Indian Institute of Technology Patna</p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="card">
                        <img className="card-img-top" src="" alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>Brahmjit Singh</strong></p>
                            <p className="card-text">Dean (planning and development), NIT kurukshetra</p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="card">
                        <img className="card-img-top" src="" alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>Rajeev kappor</strong></p>
                            <p className="card-text">Prof, DTU</p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="card">
                        <img className="card-img-top" src="" alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>Naveen</strong></p>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <h3 className='text-center my-4'><strong>INTERNATIONAL ADVISORY COMMITTEE</strong></h3>
            <div className="row container mx-auto" style={{width: '50rem'}}>
                <div className="col-sm-4">
                    <div className="card">
                        <img className="card-img-top" src="" alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>Roop Mahajan</strong></p>
                            <p className="card-text">Virginia Tech</p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="card">
                        <img className="card-img-top" src="" alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>Dr Ravishankar Dudhe</strong></p>
                        </div>
                    </div>    
                </div>
                <div className="col-sm-4">
                    <div className="card">
                        <img className="card-img-top" src="" alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>Manipal Academy of Higher education Dubai</strong></p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="card">
                        <img className="card-img-top" src="" alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>Asma Khabba Cadi Ayyad University</strong></p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="card">
                        <img className="card-img-top" src="" alt="Card image cap"/>
                        <div className="card-body">
                            <p className="card-text"><strong>Mohsin koohestani</strong></p>
                            <p className="card-text">Italy</p>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="container my-5" style={{width: "75%"}}>
                <h3 className='text-center my-5'><strong>NATIONAL ADVISORY COMMITTEE</strong></h3>
                <div className="row row-cols-1 row-cols-md-3 g-4">
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="..." className="card-img-top" alt="..."> */}
                        <div className="card-body">
                            <p className="card-title"><strong>Prof. MV Kartikey</strong></p>
                            <p className="card-text">Director, IIITDM kanchipuram</p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="..." className="card-img-top" alt="..."> */}
                        <div className="card-body">
                            <p className="card-title"><strong>Prof. Harish Kumar Sardana</strong></p>
                            <p className="card-text">Director, IIIT Raichur</p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="..." className="card-img-top" alt="..."> */}
                        <div className="card-body">
                            <p className="card-title"><strong>Prof. CC Tripthati</strong></p>
                            <p className="card-text">Director, NITTR Bhopal</p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="..." className="card-img-top" alt="..."> */}
                        <div className="card-body">
                            <p className="card-title"><strong>Prof. Vimal Bhatia</strong></p>
                            <p className="card-text">IIT indore</p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="..." className="card-img-top" alt="..."> */}
                        <div className="card-body">
                            <p className="card-title"><strong>Prof. Ram Bilas Pachori</strong></p>
                            <p className="card-text">IIT Indore</p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="..." className="card-img-top" alt="..."> */}
                        <div className="card-body">
                            <p className="card-title"><strong>Prof. Ajitha Shenoy K B</strong></p>
                            <p className="card-text">Manipal Institute of Technology Manipal, Manipal Academy of Higher Education</p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="..." className="card-img-top" alt="..."> */}
                        <div className="card-body">
                            <p className="card-title"><strong>Prof. Preetam Kumar</strong></p>
                            <p className="card-text">Indian Institute of Technology Patna</p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="..." className="card-img-top" alt="..."> */}
                        <div className="card-body">
                            <p className="card-title"><strong>Prof. Brahmjit Singh</strong></p>
                            <p className="card-text">NIT kurukshetra</p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="..." className="card-img-top" alt="..."> */}
                        <div className="card-body">
                            <p className="card-title"><strong>Prof. Rajeev kappor</strong></p>
                            <p className="card-text">DTU</p>
                        </div>
                        </div>
                    </div>
                    {/* <div className="col">
                        <div className="card h-100 text-center"> */}
                        {/* <img src="..." className="card-img-top" alt="..."> */}
                        {/* <div className="card-body">
                            <p className="card-title"><strong>Naveen</strong></p>
                        </div>
                        </div>
                    </div> */}
                </div>
                <h3 className='text-center my-5'><strong>INTERNATIONAL ADVISORY COMMITTEE</strong></h3>
                <div className="row row-cols-1 row-cols-md-3 g-4">
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="..." className="card-img-top" alt="..."> */}
                        <div className="card-body">
                            <p className="card-title"><strong>Prof. Roop Mahajan</strong></p>
                            <p className="card-text">Virginia Tech</p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="..." className="card-img-top" alt="..."> */}
                        <div className="card-body">
                            <p className="card-title"><strong>Prof. Ravishankar Dudhe</strong></p>
                            <p className="card-text">Manipal Academy of Higher education Dubai</p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="..." className="card-img-top" alt="..."> */}
                        <div className="card-body">
                            <p className="card-title"><strong>Prof. Mohsen koohestani</strong></p>
                            <p className="card-text">Institute of Electronics and Digital Technologies, France</p>
                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="..." className="card-img-top" alt="..."> */}
                        <div className="card-body">
                            <p className="card-title"><strong>Prof. Jaume Anguera</strong></p>
                            <p className="card-text">Universitat Ramon LLull</p>
                            {/* <p className="card-text"><strong>jaume.anguera@ignion.com</strong></p>
                            <p className="card-text"><strong>jaume.anguera@salle.url.edu</strong></p> */}

                        </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="..." className="card-img-top" alt="..."> */}
                        <div className="card-body">
                            <p className="card-title"><strong>Prof. Asma Khabba</strong></p>
                            <p className="card-text">Cadi Ayyad University, Marrakesh</p>

                        </div>
                    </div>
                    </div>
                    <div className="col">
                        <div className="card h-100 text-center">
                        {/* <img src="..." className="card-img-top" alt="..."> */}
                        <div className="card-body">
                            <p className="card-title"><strong>Prof. Md. Mamun Bin Ibne Reaz</strong></p>
                            <p className="card-text">Universiti Kebangsaan Malaysia</p>

                        </div>
                        </div>
                    </div>
                </div>
            </div>
      </>
    )
}